<template>
  <div class="app-content content" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <transition name="zoom-fade" mode="out-in">
          <b-card no-body>
            <div class="m-2">
            </div>
            <div class="position-relative table-responsive">
              <table class="table b-table">
                <thead>
                <tr>
                  <th rowspan="2">#</th>
                  <th colspan="2" class="text-center">Баланс</th>
                  <th rowspan="2">Имя Фамилия</th>
                  <th rowspan="2">Название компании</th>
                  <th rowspan="2">Роли</th>
                </tr>
                <tr>
                  <th>Клиент</th>
                  <th>Поставщик</th>
                </tr>
                </thead>
                <tbody v-if="!loading">
                  <tr v-for="(balance, index) in users" :key="index" >
                  <td>
                    <router-link :to="{ name: 'user-details', params: { id: balance.user.id }}" class="font-weight-bold">
                      #{{ balance.user.id }}
                    </router-link>
                  </td>
                  <td>
                    <div class="text-nowrap" v-for="(balance, index) in balance.client" :key="index">
                      <span v-if="balance.amount !== 0" :class="['amount', balance.amount > 0 ? 'positive' : 'negative']">
                        {{ balance.amount }}
                      </span> <span v-if="balance.amount !== 0" class="currency">{{ balance.currency_name }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="text-nowrap" v-for="(balance, index) in balance.supplier" :key="index">
                      <span :class="['amount', balance.amount > 0 ? 'positive' : 'negative']" v-if="balance.amount !== 0">
                        {{ balance.amount }}
                      </span>
                      <span class="currency" v-if="balance.amount !== 0">{{ balance.currency_name }}</span>
                    </div>
                  </td>
                  <td style="white-space: nowrap">
                    {{ balance.user.name }} {{ balance.user.surname }}
                  </td>
                    <td>{{ balance.user.company_name }}</td>
                    <td>{{ balance.user.roles.join(', ') }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </b-card>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard, BFormInput, BButton, BAvatar, BPagination, BTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  name: 'Settlements',
  components: {
    BCard, BFormInput, BButton, BAvatar, BPagination, BTooltip, vSelect,
  },
  data() {
    return {
      users: [],
      loading: true,
    };
  },
  async mounted() {
    await this.getUsers();
  },
  methods: {
    async getUsers() {
      this.loading = true;
      this.users = (await this.$api.reports.settlements()).data;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.per-page-selector {
  width: 90px;
}

::v-deep .v-select {
  width: 220px;
}
.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.amount {
  white-space: nowrap;

  & span.amount {
    font-size: 18px;

  }

  & span.currency {
    font-size: 10px;
  }
}

.row-green {
  background-color: #e6ffe6;
}

.row-warning {
  background-color: #fff3cd;
}
span.amount {
  font-size: 18px;
}
.text-dashed {
  border-bottom: 1px dotted #6c6c6c;
  cursor: pointer;
}
.positive {
  color: $success;
}
.negative {
  color: $danger;
}
span.currency {
  font-size: 10px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
